import React from 'react';
import ContentPage from '../../components/ContentPage';
import PageImage from '../../components/PageImage';
import LinksList from '../../components/LinksList';

import image from '../../images/undraw_completed_tasks.svg';

function ServicesQualityPage() {

    const navLinks = [
        {label: 'Analysis and Design', route:'../design'},
        {label: 'Testing', route:'../testing'},
        {label: 'Training', route:'../training'},
    ]

    return <ContentPage>
        <h1>Quality Control</h1>
        <p>Building a web application, mobile app, or website can be a significant undertaking. But it is easy for a 
            project to fail to meet its objectives if the right approach is not taken.</p>
        <PageImage image={image} alt='Quality control' />
        <p>At Coralpeak Systems, we don’t just do programming. As part of our development process, we want to make sure 
            that your product is the right solution for your objectives and that it is successfully rolled out to your 
            user community.</p>
        <p>Our development support skills are also offered as standalone services if you need to enhance a project 
            that is being developed in-house or by contractors. We are always happy to discuss your requirements and 
            find ways to help you achieve the best outcome for your project.</p>
        <LinksList links={navLinks} caption='Find out more about how we can help your project succeed:' />
    </ContentPage>

}

export default ServicesQualityPage;